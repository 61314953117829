<template>
	<div class="filter-field filter-field-taxon">
		<BaseDropdown
			:clickaway="clickaway"
			text="Gruppe"
			title="Nach Gruppe filtern"
		>
			<FilterItem
				v-for="(item, key) in dinosaurs"
				:key="key"
				:icon="true"
				:item="item"
				@change="_onChange"
			/>
			<FilterItem
				v-for="(item, key) in ((taxon) ? [getTaxon.current] : taxa)"
				:key="key"
				:current="taxon"
				:icon="true"
				:item="item"
				:parent="getTaxon.parent"
				@change="_onChange"
			/>
		</BaseDropdown>
	</div>
</template>

<script>
import {mapState} from 'vuex';
import {FilterMixin} from '@/mixins';
import {groupBy} from '@schascha/brabbelback';

export default {
	components: {
		FilterItem: () => import(/* webpackChunkName: "FilterItem" */ '@/components/filter/FilterItem')
	},
	mixins: [FilterMixin],
	props: {
		clickaway: {
			type: Boolean,
			default: true
		}
	},
	computed: {
		...mapState(['taxon']),
		dinosaurs() {
			return (!this.taxon) ? {
				Dinosaurier: this.$store.state.taxa.find(({name}) => name === 'Dinosauria').children
			} : [];
		},
		taxa() {
			return groupBy(this.$store.state.taxa, 'group');
		},
		getTaxon() {
			return this.$store.getters.getTaxon(this.taxon);
		}
	},
	methods: {
		_onChange(e) {
			const {name} = e;
			this.changeFilter('taxon', name);
		}
	}
};
</script>
